<template>
  <div class="device-support-overlay">
    <div class="mx-auto max-w-sm py-12 px-8 text-center">
      <img src="~assets/img/rotate-device.svg" alt="Rotate device to landscape" class="mx-auto mb-4">
      <p>
        Please turn your device sideways to landscape mode to view this feature.
      </p>
    </div>
  </div>
</template>

<style>
.device-support-overlay {
  display: none;
}

@media screen and (max-width: 640px) {
  .device-support-overlay {
    @apply bg-gray-50;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 3;
  }
}
</style>
